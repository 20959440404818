<template>
  <b-container fluid>
    <vehicle-acceptance />
  </b-container>
</template>

<script>
import VehicleAcceptance from "@/components/vehicle/VehicleAcceptance.vue";

export default {
  components: {
    "vehicle-acceptance": VehicleAcceptance
  }
};
</script>
