<template>
  <base-vehicle-action :vehicleId="vehicleId" ref="base" />
</template>

<script>
import BaseVehicleAction from "./BaseVehicleAction.vue";
import { VEHICLE_ACCEPTANCE_OR_CHECKOUT } from "@/const/events.js";
import { ACCEPTANCE } from "@/const/vehicle-load-types.js";
import redirectWithChecksMixin from "@/mixins/redirect-with-check.js";

import { mapActions } from "vuex";

export default {
  mixins: [redirectWithChecksMixin],
  components: {
    "base-vehicle-action": BaseVehicleAction
  },
  computed: {
    vehicleId() {
      return Number(this.$route.params.vehicleId);
    }
  },
  methods: {
    ...mapActions(["vehicleLoad/loadVehicle"]),
    acceptVehicle({ loadItems, customCheckResults }) {
      let vm = {
        vehicleId: this.vehicleId,
        type: ACCEPTANCE,
        loadItems,
        customCheckResults
      };
      this["vehicleLoad/loadVehicle"](vm)
        .then(resp => {
          if (resp.data != null && resp.data.autoIncidents != null)
            this.$refs.base.notifyIncidents(resp.data.autoIncidents);

          this.redirectToRoute("driver-home");
        })
        .catch(err => {
          if (err.data && err.data.errorCode) {
            alert(this.$t("vehicle-acceptance-checkout.error." + err.data.errorCode, err.data.additionalData));
          } else {
            alert(this.$t("vehicle-acceptance-checkout.error.error-acceptance-vehicle"));
          }
        });
    }
  },
  mounted() {
    this.$bus.on(VEHICLE_ACCEPTANCE_OR_CHECKOUT, this.acceptVehicle);
  },
  beforeDestroy() {
    this.$bus.off(VEHICLE_ACCEPTANCE_OR_CHECKOUT, this.acceptVehicle);
  }
};
</script>
